import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { UtmParams } from '@models/shared/utm-params.model';

const UTM_STORAGE_KEY = 'utmParams';

@Injectable({ providedIn: 'root' })
export class UtmService {
  private utmParams?: UtmParams;

  constructor() {
    this.loadFromStorage();
  }

  captureFromQueryParams(paramMap: ParamMap): void {
    const utm: UtmParams = {
      utm_source: paramMap.get('utm_source') || undefined,
      utm_medium: paramMap.get('utm_medium') || undefined,
      utm_campaign: paramMap.get('utm_campaign') || undefined,
      utm_content: paramMap.get('utm_content') || undefined,
    };

    // Only store if at least one value exists
    if (Object.values(utm).some((v) => v !== undefined)) {
      this.utmParams = utm;
      localStorage.setItem(UTM_STORAGE_KEY, JSON.stringify(utm));
    }
  }

  getUtmParams(): UtmParams | undefined {
    return this.utmParams;
  }

  private loadFromStorage(): void {
    const stored = localStorage.getItem(UTM_STORAGE_KEY);
    if (stored) {
      this.utmParams = JSON.parse(stored);
    }
  }

  clear(): void {
    localStorage.removeItem(UTM_STORAGE_KEY);
    this.utmParams = undefined;
  }
}
